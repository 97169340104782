/* DEFAULT */
$baseline: 1.25;
$primary-font: "Roboto Flex";

/* FONT WEIGHT */
$thin: 100;
$light: 300;
$regular: 400;
$light-bold: 500;
$med-bold: 600;
$bold: 700;
$black: 900;

/* FONT SIZE */
$font-xs: 0.714rem;
$font-sm: 0.875rem;
$font-default: 1rem;
$font-md: 1.143rem;
$font-lg: 1.429rem;
$font-xlg: 1.786rem;
$font-xxlg: 2.143rem;

/* BREAKPOINTS */
$breakpoints: (
  xsmall: 480px,
  small: 736px,
  medium: 980px,
  large: 1280px,
  xlarge: 1620px,
  xxlarge: 1690px,
);

// V2
$black: #060204;
$white: #fff;

$grey-50: #fafafa;
$grey-100: #f6f6f6;
$grey-200: #eaecf0;
$grey-300: #e4e4e4;
$grey-400: #c1c1c1;
$grey-500: #a2a2a2;
$grey-600: #475467;
$grey-700: #656565;
$grey-800: #464646;
$grey-900: #242424;

$primary-lighter: #edf6ff99;
$primary-light: #edf6ff80;
$primary-med: #c2ddf9;
$primary: #005dbc;
$primary-dark: #003871;
$primary-darker: #00254b;

$rich-lighter: #f4f6f9;
$rich-light: #ccd2d9;
$rich: #a9b1b9;
$rich-dark: #768595;
$rich-med-dark: #3c424f;
$rich-darker: #011f3f;

$warning-light: #fff3d1;
$warning: #f2b100;
$warning-medium: #c38f01;
$warning-dark: #b54708;
$warning-darker: #493500;

$error-lighter: #fef3f2;
$error-light: #fce7eb;
$error: #e11232;
$error-dark: #44050f;

$success-dark: #033e2d;
$success: #067647;
$success-light: #dcfae6;

// Deprecated

/* GREY */
$rich-lighter-grey: #f9fafb;
$rich-light-grey: #f4f6f9;
$rich-med-grey: #ccd2d9;
$med-grey: #d2d2d2;
$dark-grey: #ababab;
$darker-grey: #727272;
$light-grey: #f4f6f9;
