@import "core/variables";
@import "index";

:root {
  font-size: 14px;
}

html {
  font-family: $primary-font;
  font-size: $font-default;
  line-height: $baseline;
}

html,
body {
  position: fixed;
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  * {
    box-sizing: border-box;
  }
}

body {
  font-family: $primary-font;

  @include default($regular);
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label {
  color: $black;

  @include default($bold);
  padding-right: 0.625rem;
  flex: 1;
}

h1 {
  @include xlarge($light-bold);
  color: $rich-darker;
  margin: 0;
}

h2 {
  @include large($light-bold);
  color: $rich-darker;
  margin: 0;
}

h3 {
  @include medium($light-bold);
  color: $rich-darker;
}

.breadcrumb {
  @include large($bold);
  font-size: $font-md;
  color: $rich;
  text-decoration: none;
  cursor: pointer;

  &:hover:not(.active) {
    text-decoration: underline;
  }

  &.active {
    color: $primary;
  }
}

.divider-bar {
  width: 100%;
  border-top: 1px solid $med-grey;
  margin: 1.3rem 0;
}

.theme-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $white;
  box-shadow: 0 -4px 8px 0 rgba(0, 94, 188, 10%);
  border-radius: 8px 8px 0 0;
  position: sticky;
  bottom: 0;
}

.skeletons-overlay {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 20%, rgba(255, 255, 255, 100%) 100%);
  width: 100%;
  height: 100%;
}
