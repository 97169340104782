@import "core/variables";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "core/mixins";

.ag-header-cell-label {
  display: flex;
  justify-content: space-between;
}

.ag-theme-alpine .ag-header {
  border-bottom: 0.072rem solid $med-grey;
  border-top: 0.072rem solid $dark-grey;
  z-index: 1;

  .ag-header-cell {
    &:hover {
      color: $primary;
    }
  }
}

.ag-theme-alpine {
  --ag-foreground-color: #{$black};
  --ag-background-color: #{$white};
  --ag-header-foreground-color: #{$black};
  --ag-header-background-color: #{$light-grey};
  --ag-odd-row-background-color: #{$white};
  --ag-font-size: $font-default;
  --ag-font-weight: 700;
  --ag-alpine-active-color: #{$primary};
  --ag-row-hover-color: #{$primary-light};
  --ag-header-cell-hover-background-color: #{$primary-med};
  --ag-header-cell-moving-background-color: #{$primary-med};
}

.first-column {
  color: $dark-grey;
  background-color: $light-grey;
}

.ag-overlay-no-rows-wrapper {
  @include quad-background;
}

.ag-overlay-wrapper {
  text-align: unset;
}

.ag-header-row {
  z-index: 1;
}

.ag-row {
  cursor: pointer;
}
