@import "variables";

button {
  all: unset;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-flex;
  padding: 0.6rem 0.8rem;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;

  &.icon-button {
    padding: 0.25rem 0.625rem 0.25rem 0.25rem;
    gap: 0.125rem;
  }
}

icon {
  transform: scale(0.67);
}

.round-icon-button {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;

  &:hover {
    background: $rich-light-grey;
  }

  &:active {
    background-color: $rich-light-grey;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
}

.btn-primary {
  background-color: $primary;
  color: $white;
  border-radius: 0.25rem;
  height: 2rem;

  &:hover {
    background-color: $primary-dark;
  }

  &:disabled {
    background-color: $rich-med-grey;
    cursor: not-allowed;
  }

  &:focus {
    background-color: $primary-dark;
  }
}

.btn-secondary {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 0.25rem;
  height: 2rem;

  &:hover {
    background-color: $primary-lighter;
  }

  &:disabled {
    background-color: $rich-med-grey;
    color: $rich-dark;
    cursor: not-allowed;
    border: none;
  }

  &:focus {
    background-color: $primary-lighter;
  }
}

.btn-secondary-no-border {
  background-color: $white;
  color: $primary;
  border-radius: 0.25rem;

  &:hover {
    background-color: $primary-lighter;
  }

  &:disabled {
    background-color: $rich-med-grey;
    cursor: not-allowed;
    border: none;
  }

  &:focus {
    background-color: $primary-lighter;
  }
}

.btn-text-only {
  color: $black;
  background-color: $white;
  border-radius: 0.25rem;
  height: 2rem;

  &:hover {
    color: $primary;
  }

  &:disabled {
    cursor: not-allowed;
    color: $rich-med-grey;
  }

  &:focus {
    background-color: $light-grey;
  }
}

.delete-button {
  cursor: pointer;
  color: $darker-grey;

  &:hover {
    color: $dark-grey;
  }
}
