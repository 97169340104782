@import "variables";

@mixin xxlarge($font-weight) {
  font-size: $font-xxlg;
  font-weight: $font-weight;
  line-height: 48px;
  letter-spacing: -0.96px;
}

@mixin xlarge($font-weight) {
  font-size: $font-xlg;
  font-weight: $font-weight;
  line-height: 38px;
  color: $black;
}

@mixin large($font-weight) {
  font-size: $font-lg;
  font-weight: $font-weight;
  line-height: 38px;
  color: $black;
}

@mixin medium($font-weight) {
  font-size: $font-md;
  font-weight: $font-weight;
  line-height: 30px;
  color: $black;
}

@mixin default($font-weight) {
  font-size: $font-default;
  font-weight: $font-weight;
  line-height: 24px;
  color: $black;
}

@mixin small($font-weight) {
  font-size: $font-sm;
  font-weight: $font-weight;
  line-height: 20px;
  color: $black;
}
