@use "@angular/material" as mat;
@import "core/variables";
@include mat.core;

.mat-mdc-form-field {
  padding: 0;
}

.mdc-text-field {
  border-radius: 20px;
}

$custom-primary: (
  50: $primary-light,
  100: $primary-light,
  200: $primary-light,
  300: $primary-med,
  400: $primary-med,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);
$my-app-primary: mat.define-palette($custom-primary);
$my-app-accent: mat.define-palette(mat.$grey-palette);
$my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-app-theme);
