@import "variables";

/* Breakpoint mixin -- mobile first (min-width) */
@mixin breakpoint($bp, $rule: min-width) {
  @media all and (#{$rule}: map-get($breakpoints, $bp)) {
    @content;
  }
}

/* Unstyled default list */
@mixin unlist() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin text-ellipsis() {
  width: 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin quad-background() {
  background: repeating-linear-gradient(to bottom, $light-grey, $light-grey 1px, transparent 1px, transparent 48px),
    repeating-linear-gradient(to right, $light-grey, $light-grey 1px, transparent 1px, transparent 96px);
  background-color: white;
}

@mixin scrollbar() {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &:hover {
    scrollbar-color: rgba(0, 0, 0, 0%) transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $med-grey;
    border-radius: 8px;
  }
}

@mixin blue-card() {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: $primary-lighter;
  border-radius: 0.5rem;
}

@mixin tag($backgroundColor, $borderColor, $color) {
  border-radius: 3.75rem;
  border: 1px solid $borderColor;
  background: $backgroundColor;
  color: $color;
  width: fit-content;
  min-width: 3rem;
  padding: 0.3rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: default;
  font-size: $font-sm;
  font-style: normal;
  font-weight: $light-bold;

  span {
    max-width: 9.375rem;

    @include text-ellipsis;
  }

  icon {
    cursor: pointer;
  }
}

@mixin tag-primary() {
  @include tag($primary-light, $primary, $primary);
}

@mixin tag-warning() {
  @include tag($warning-light, $warning, $warning-dark);
}

@mixin tag-success() {
  @include tag($success-light, $success, $success-dark);
}

@mixin card() {
  display: flex;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  background-color: $white;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  border-radius: 8px;
  height: fit-content;
  flex-direction: column;
}
