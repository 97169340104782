@import 'variables';

input, select
 {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid $rich-med-grey;
  background: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;


  &::placeholder {
    color: $dark-grey;
    font-weight: 400;
  }

  &:invalid {
    border-color: $error;
  }

  &:focus {
    border-color: $primary;

  }

  &:hover {
    border-color: $primary;
  }

  &:disabled {
    background-color: $rich-med-grey;
    cursor: not-allowed;
    border: none
  }  
}

.invalid {
  border: 2px solid $error;
  
  &:hover {
    border-color: $error;
  }
}